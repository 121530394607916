import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage(t('passwordsDontMatch'));
      return;
    }
    try {
      const res = await axiosInstance.post(`/api/users/reset-password/${token}`, { password });
      setMessage(t(res.data.msg));
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } catch (err) {
      setMessage(t(err.response?.data?.msg || 'serverError'));
    }
  };

  return (
    <div>
      <h2>{t('resetPassword')}</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          name="password"
          placeholder={t('newPassword')}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          name="confirmPassword"
          placeholder={t('confirmPassword')}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <button type="submit">{t('resetPassword')}</button>
      </form>
    </div>
  );
}

export default ResetPassword;