import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../utils/axiosConfig';
import './ContactUs.css';

function ContactUs() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    console.log('Submitting form:', formData);
    try {
      const res = await axiosInstance.post('/api/contact', formData);
      console.log('Response:', res.data);
      setStatus({ type: 'success', message: t('messageSent') });
      setFormData({ email: '', message: '' });
    } catch (err) {
      console.error('Error:', err.response || err);
      setStatus({ type: 'error', message: t(err.response?.data?.msg || 'errorSendingMessage') });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-container">
      <h2>{t('contactUs')}</h2>
      <p className="contact-description">{t('contactDescription')}</p>
      <p className="response-time">{t('responseTime')}</p>
      
      {status && (
        <div className={`message ${status.type}`}>
          {status.message}
        </div>
      )}

      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="email">{t('email')}</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder={t('emailPlaceholder')}
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">{t('message')}</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="5"
            required
            placeholder={t('messagePlaceholder')}
          />
        </div>

        <button 
          type="submit" 
          className="submit-button"
          disabled={isSubmitting}
        >
          {isSubmitting ? t('sending') : t('send')}
        </button>
      </form>
    </div>
  );
}

export default ContactUs;
