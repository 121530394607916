import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Header.css';

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole'));
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState('zh');

  const navRef = useRef(null);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
    setUserRole(null);
    setUsername(null);
    history.push('/');
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLanguageChange = () => {
    const newLang = currentLanguage === 'en' ? 'zh' : 'en';
    setCurrentLanguage(newLang);
    i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setCurrentLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }

    const checkLoginStatus = () => {
      const token = localStorage.getItem('token');
      const storedUserRole = localStorage.getItem('userRole');
      const storedUsername = localStorage.getItem('username');
      setIsLoggedIn(!!token);
      setUserRole(storedUserRole);
      setUsername(storedUsername);
    };

    window.addEventListener('storage', checkLoginStatus);
    checkLoginStatus();

    return () => {
      window.removeEventListener('storage', checkLoginStatus);
    };
  }, [i18n]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isNavOpen && navRef.current && !navRef.current.contains(event.target)) {
        setIsNavOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNavOpen]);

  const isAdminOrManager = userRole === 'admin' || userRole === 'manager';

  return (
    <header className="header">
      <nav className="nav-container" ref={navRef}>
        <Link to="/" className="logo-link" onClick={() => setIsNavOpen(false)}>
          <div className="logo">diiink</div>
        </Link>
        <button className="nav-toggle" onClick={toggleNav}>
          ☰
        </button>
        <ul className={`nav-list ${isNavOpen ? 'nav-open' : ''}`}>
          <li><Link to="/" className="nav-link" onClick={() => setIsNavOpen(false)}>{t('home')}</Link></li>
          {isLoggedIn && isAdminOrManager && (
            <li><Link to="/create-event" className="nav-link" onClick={() => setIsNavOpen(false)}>{t('createEvent')}</Link></li>
          )}
          {isAdminOrManager && (
            <li><Link to="/admin" className="nav-link" onClick={() => setIsNavOpen(false)}>{t('adminPanel')}</Link></li>
          )}
          <li><Link to="/contact" className="nav-link" onClick={() => setIsNavOpen(false)}>{t('contactUs')}</Link></li>
          <li className="mobile-only">
            {isLoggedIn ? (
              <>
                <span className="username">{username}</span>
                <span className="user-role">({t(userRole)})</span>
                <button className="language-button" onClick={() => {
                  handleLanguageChange();
                  setIsNavOpen(false);
                }}>
                  {currentLanguage === 'en' ? '中文' : 'English'}
                </button>
                <button onClick={() => {
                  handleLogout();
                  setIsNavOpen(false);
                }} className="auth-button">{t('logout')}</button>
              </>
            ) : (
              <>
                <button className="language-button" onClick={() => {
                  handleLanguageChange();
                  setIsNavOpen(false);
                }}>
                  {currentLanguage === 'en' ? '中文' : 'English'}
                </button>
                <Link to="/register" className="auth-button" onClick={() => setIsNavOpen(false)}>{t('register')}</Link>
                <Link to="/login" className="auth-button" onClick={() => setIsNavOpen(false)}>{t('login')}</Link>
              </>
            )}
          </li>
        </ul>
        <div className="auth-section desktop-only">
          {isLoggedIn ? (
            <>
              <span className="username">{username}</span>
              <span className="user-role">({t(userRole)})</span>
              <button className="language-button" onClick={handleLanguageChange}>
                {currentLanguage === 'en' ? '中文' : 'English'}
              </button>
              <button onClick={handleLogout} className="auth-button">{t('logout')}</button>
            </>
          ) : (
            <>
              <button className="language-button" onClick={handleLanguageChange}>
                {currentLanguage === 'en' ? '中文' : 'English'}
              </button>
              <Link to="/register" className="auth-button">{t('register')}</Link>
              <Link to="/login" className="auth-button">{t('login')}</Link>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
