import React, { useState, useEffect, useCallback, useReducer } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import Modal from '../components/Modal';
import { useTranslation } from 'react-i18next';
import '../components/Button.css';
import axiosInstance from '../utils/axiosConfig';

function AdminPanel() {
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', message: '', onConfirm: null });
  const { t } = useTranslation();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [userRole] = useState(localStorage.getItem('userRole'));

  const fetchUsers = useCallback(async () => {
    try {
      console.log('Fetching users...');
      const res = await axiosInstance.get('/api/users/admin');
      console.log('Fetched users:', res.data);
      setUsers(res.data);
    } catch (err) {
      console.error('Error fetching users:', err.response ? err.response.data : err.message);
    }
  }, []);

  const fetchEvents = useCallback(async () => {
    try {
      const res = await axiosInstance.get('/api/events');
      setEvents(res.data);
    } catch (err) {
      console.error('Error fetching events:', err.response ? err.response.data : err.message);
    }
  }, []);

  useEffect(() => {
    console.log('AdminPanel mounted');
    fetchUsers();
    fetchEvents();
    return () => {
      console.log('AdminPanel unmounted');
    };
  }, [fetchUsers, fetchEvents]);

  const handleRoleChange = async (userId, newRole) => {
    if (userRole !== 'admin') {
      console.error('Only admins can change user roles');
      return;
    }
    try {
      const res = await axios.put(`${API_BASE_URL}/api/users/update-role/${userId}`, 
        { role: newRole },
        {
          headers: { 'x-auth-token': localStorage.getItem('token') },
          withCredentials: true
        }
      );
      setUsers(users.map(user => user._id === userId ? res.data : user));
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteUser = useCallback(async (userId) => {
    console.log('handleDeleteUser called with userId:', userId);
    setModalContent({
      title: t('confirmDelete'),
      message: t('confirmDeleteUser'),
      onConfirm: async () => {
        console.log('Delete user confirmation clicked');
        try {
          console.log('Attempting to delete user:', userId);
          const response = await axios.delete(`${API_BASE_URL}/api/users/delete/${userId}`, {
            headers: { 'x-auth-token': localStorage.getItem('token') },
            withCredentials: true
          });
          
          console.log('Delete user response:', response);
          
          if (response.status === 200) {
            setUsers(prevUsers => {
              const updatedUsers = prevUsers.filter(user => user._id !== userId);
              console.log('Users after deletion:', updatedUsers);
              return updatedUsers;
            });
            setModalOpen(false);
          } else {
            throw new Error('Failed to delete user');
          }
        } catch (err) {
          console.error('Error deleting user:', err.response ? err.response.data : err.message);
          setModalContent({
            title: t('error'),
            message: t('errorDeletingUser') + ': ' + (err.response ? err.response.data : err.message),
            onConfirm: () => setModalOpen(false)
          });
        }
      }
    });
    console.log('Setting modal open to true');
    setModalOpen(true);
  }, [t]);

  const handleDeleteEvent = useCallback(async (eventId) => {
    console.log('handleDeleteEvent called with eventId:', eventId);
    setModalContent({
      title: t('confirmDelete'),
      message: t('confirmDeleteEvent'),
      onConfirm: async () => {
        console.log('Delete event confirmation clicked');
        try {
          console.log('Attempting to delete event:', eventId);
          const response = await axios.delete(`${API_BASE_URL}/api/events/${eventId}`, {
            headers: { 'x-auth-token': localStorage.getItem('token') },
            withCredentials: true
          });
          
          if (response.status === 200) {
            setEvents(prevEvents => {
              const updatedEvents = prevEvents.filter(event => event._id !== eventId);
              console.log('Events after deletion:', updatedEvents);
              return updatedEvents;
            });
            setModalOpen(false);
          } else {
            throw new Error('Failed to delete event');
          }
        } catch (err) {
          console.error('Error deleting event:', err.response ? err.response.data : err.message);
          setModalContent({
            title: t('error'),
            message: t('errorDeletingEvent') + ': ' + (err.response ? err.response.data : err.message),
            onConfirm: () => setModalOpen(false)
          });
        }
      }
    });
    console.log('Setting modal open to true');
    setModalOpen(true);
    // Force a re-render
    forceUpdate();
  }, [t]);

  useEffect(() => {
    console.log('Users state updated:', users);
  }, [users]);

  useEffect(() => {
    console.log('Events state updated:', events);
  }, [events]);

  console.log('Rendering AdminPanel, users:', users);

  useEffect(() => {
    console.log('modalOpen changed:', modalOpen);
  }, [modalOpen]);

  useEffect(() => {
    console.log('modalContent changed:', modalContent);
  }, [modalContent]);

  return (
    <div>
      <h2>{t('adminPanel')}</h2>
      <h3>{t('users')}</h3>
      <table>
        <thead>
          <tr>
            <th>{t('username')}</th>
            <th>{t('email')}</th>
            <th>{t('role')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user._id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>
                <select
                  value={user.role}
                  onChange={(e) => handleRoleChange(user._id, e.target.value)}
                  disabled={userRole !== 'admin'}
                >
                  <option value="user">{t('user')}</option>
                  <option value="manager">{t('manager')}</option>
                  <option value="admin">{t('admin')}</option>
                </select>
              </td>
              <td>
                {userRole === 'admin' && (
                  <button className="btn btn-danger" onClick={() => handleDeleteUser(user._id)}>{t('delete')}</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3>{t('events')}</h3>
      <table>
        <thead>
          <tr>
            <th>{t('title')}</th>
            <th>{t('time')}</th>
            <th>{t('location')}</th>
            <th>{t('participants')}</th>
            <th>{t('action')}</th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => (
            <tr key={event._id}>
              <td>{event.title}</td>
              <td>{(new Date(event.time)).toLocaleString()}</td>
              <td>{event.location}</td>
              <td>{event.participants.length} / {event.maxParticipants}</td>
              <td>
                <button className="btn btn-danger" onClick={() => handleDeleteEvent(event._id)}>{t('delete')}</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modalOpen && <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} {...modalContent} />}
    </div>
  );
}

export default AdminPanel;