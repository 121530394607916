const UTC_OFFSET = -8;

export function toLocalTime(date) {
  const utc = date.getTime() + (date.getTimezoneOffset() * 60000);
  return new Date(utc + (3600000 * UTC_OFFSET));
}

export function fromLocalTime(date) {
  const utc = date.getTime() - (3600000 * UTC_OFFSET);
  return new Date(utc - (date.getTimezoneOffset() * 60000));
}

export function getDayInLanguage(date, language) {
  const days = {
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    zh: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  };
  const dayIndex = new Date(date).getDay();
  return days[language === 'zh' ? 'zh' : 'en'][dayIndex];
}
