import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../utils/axiosConfig';

function CreateEvent() {
  const [formData, setFormData] = useState({
    title: '',
    time: '',
    location: '',
    maxParticipants: '',
    registrationStartTime: '',
    immediateRegistration: false,
    eventInfo: '',
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ 
      ...formData, 
      [name]: type === 'checkbox' ? checked : value 
    });
    setErrors({ ...errors, [name]: '' });
  };

  const validateForm = () => {
    const newErrors = {};
    const now = new Date();
    const eventTime = new Date(formData.time);
    const registrationStartTime = formData.immediateRegistration ? now : new Date(formData.registrationStartTime);

    if (parseInt(formData.maxParticipants) <= 0) {
      newErrors.maxParticipants = t('Limitation must be greater than 0');
    }

    if (eventTime <= now) {
      newErrors.time = t('Event time cannot be before current time');
    }

    if (!formData.immediateRegistration && registrationStartTime >= eventTime) {
      newErrors.registrationStartTime = t('Registration time cannot be after event time');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const adjustedFormData = {
          ...formData,
          time: new Date(formData.time).toISOString(),
          registrationStartTime: formData.immediateRegistration 
            ? new Date().toISOString() 
            : new Date(formData.registrationStartTime).toISOString()
        };
        await axiosInstance.post('/api/events', adjustedFormData);
        history.push('/');
      } catch (err) {
        console.error(err.response ? err.response.data : err.message);
      }
    }
  };

  return (
    <div className="create-event-container">
      <h1>{t('createEvent')}</h1>
      <form onSubmit={handleSubmit} className="create-event-form">
        <label htmlFor="title">{t('eventName')}</label>
        <input type="text" name="title" placeholder={t('eventName')} onChange={handleChange} required />
        
        <label htmlFor="time">{t('eventTime')}</label>
        <input type="datetime-local" id="time" name="time" onChange={handleChange} required />
        
        <label htmlFor="location">{t('location')}</label>
        <input type="text" name="location" placeholder={t('location')} onChange={handleChange} required />
        
        <label htmlFor="maxParticipants">{t('limitation')}</label>
        <input type="number" name="maxParticipants" placeholder={t('maxParticipants')} onChange={handleChange} required min="1" />
        {errors.maxParticipants && <p className="error">{errors.maxParticipants}</p>}
        
        <div className="checkbox-container">
          <input 
            type="checkbox" 
            id="immediateRegistration" 
            name="immediateRegistration" 
            checked={formData.immediateRegistration} 
            onChange={handleChange}
          />
          <label htmlFor="immediateRegistration">{t('enableImmediateRegistration')}</label>
        </div>
        
        {!formData.immediateRegistration && (
          <>
            <label htmlFor="registrationStartTime">{t('registrationStartTime')}</label>
            <input 
              type="datetime-local" 
              id="registrationStartTime" 
              name="registrationStartTime" 
              onChange={handleChange} 
              required={!formData.immediateRegistration}
            />
            {errors.registrationStartTime && <p className="error">{errors.registrationStartTime}</p>}
          </>
        )}
        
        <label htmlFor="eventInfo">{t('eventInfo')}</label>
        <textarea
          name="eventInfo"
          id="eventInfo"
          placeholder={t('eventInfo')}
          onChange={handleChange}
          rows="4"
          className="event-info-textarea"
        />
        
        <button type="submit">{t('createEvent')}</button>
      </form>
    </div>
  );
}

export default CreateEvent;
