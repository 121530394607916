import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import Modal from '../components/Modal';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from '../config';
import '../components/Button.css';
import axios from 'axios';
import { getDayInLanguage } from '../utils/dateUtils';

const isLoggedIn = () => {
  return !!localStorage.getItem('token');
};

function EventDetails() {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [countdown, setCountdown] = useState('');
  const [canRegister, setCanRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', message: '' });
  const [visitorNames, setVisitorNames] = useState(['', '', '']);
  const { t, i18n } = useTranslation();
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole'));
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [participantToCancel, setParticipantToCancel] = useState(null);

  const location = useLocation();
  const shareUrl = window.location.origin + location.pathname;

  const checkRegistrationStatus = useCallback((startTime) => {
    const now = new Date().getTime();
    const start = new Date(startTime).getTime();
    const timeLeft = start - now;

    if (timeLeft <= 0) {
      setCountdown(t('registrationIsOpen'));
      const hasRefreshed = sessionStorage.getItem(`hasRefreshed_event_${id}`);
      if (!hasRefreshed) {
        const canRegisterNow = now >= start;
        setCanRegister(canRegisterNow);
        sessionStorage.setItem(`hasRefreshed_event_${id}`, 'true');
        window.location.reload();
      }
    } else {
      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
      setCountdown(t('registrationOpensIn', { days, hours, minutes, seconds }));
    }
  }, [t, id]);

  useEffect(() => {
    let isMounted = true;
    const source = axios.CancelToken.source();

    const fetchEventDetails = async () => {
      try {
        const res = await axiosInstance.get(`${API_BASE_URL}/api/events/${id}`, {
          withCredentials: true,
          cancelToken: source.token
        });
        if (isMounted) {
          setEvent(res.data);
          const now = new Date().getTime();
          const start = new Date(res.data.registrationStartTime).getTime();
          const canRegisterNow = now >= start;
          setCanRegister(canRegisterNow);
          checkRegistrationStatus(res.data.registrationStartTime);
        }
      } catch (err) {
        if (axiosInstance.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          console.error(err);
        }
      }
    };

    fetchEventDetails();

    return () => {
      isMounted = false;
      source.cancel('Component unmounted');
    };
  }, [id, checkRegistrationStatus]);

  useEffect(() => {
    if (!event) return;

    const timer = setInterval(() => {
      checkRegistrationStatus(event.registrationStartTime);
    }, 1000);

    return () => clearInterval(timer);
  }, [event, checkRegistrationStatus]);

  useEffect(() => {
    if (isLoggedIn()) {
      const username = localStorage.getItem('username') || '';
      setVisitorNames([username, '', '']);
    }
  }, []);

  const handleRegister = async () => {
    setIsLoading(true);
    try {
      const names = visitorNames.filter(name => name.trim() !== '');
      if (names.length === 0) {
        throw new Error('At least one name is required');
      }

      const payload = isLoggedIn() ? 
        { 
          username: names[0], 
          additionalNames: names.slice(1) 
        } : 
        { visitorNames: names };
        
      const res = await axiosInstance.post(`/api/events/${id}/register`, payload);
      const updatedEvent = await axiosInstance.get(`/api/events/${id}`);
      setEvent(updatedEvent.data);
      
      if (isLoggedIn()) {
        setVisitorNames([names[0], '', '']);
      } else {
        setVisitorNames(['', '', '']);
      }

      let message = '';
      switch(res.data.status) {
        case 'waitlisted':
          message = t('eventFullWaitlisted');
          break;
        case 'partially_waitlisted':
          message = t('someParticipantsWaitlisted');
          break;
        default:
          message = t('registrationSuccessful');
      }

      setModalContent({
        title: t('registrationStatus'),
        message: message
      });
      setModalOpen(true);
    } catch (err) {
      console.error(err);
      let errorMessage = err.response?.data?.msg || 'Server Error';
      
      if (err.response?.status === 401 && err.response?.data?.msg === 'No token, authorization denied') {
        localStorage.clear();
        window.location.href = '/login';
        return;
      }
      
      if (errorMessage === 'alreadyRegisteredForEvent') {
        const name = err.response.data.username;
        errorMessage = `${name} ${t('alreadyRegisteredForEvent')}`;
      } else {
        errorMessage = t(errorMessage);
      }
      
      setModalContent({
        title: t('error'),
        message: errorMessage
      });
      setModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelRegistration = async (participantName) => {
    try {
      const encodedName = encodeURIComponent(participantName);
      await axiosInstance.post(`/api/events/${id}/cancel/${encodedName}`);
      const updatedEvent = await axiosInstance.get(`/api/events/${id}`);
      setEvent(updatedEvent.data);
      
      const userRole = localStorage.getItem('userRole');
      const message = userRole === 'manager' || userRole === 'admin' 
        ? t('registrationCancelledByManager')
        : t('registrationCancelled');

      setModalContent({
        title: t('cancellationConfirmed'),
        message: message,
        onConfirm: null
      });
      setModalOpen(true);
    } catch (err) {
      console.error(err);
      setModalContent({
        title: t('error'),
        message: err.response?.data?.msg || t('cancellationError'),
        onConfirm: null
      });
      setModalOpen(true);
    }
  };

  const handleCancelClick = (participant) => {
    setModalContent({
      title: t('confirmCancellation'),
      message: t('confirmCancellationMessage', { name: participant.username }),
      onConfirm: () => handleCancelRegistration(participant.username)
    });
    setModalOpen(true);
  };

  const handleConfirmCancel = async () => {
    if (participantToCancel) {
      await handleCancelRegistration(participantToCancel.username);
      setConfirmModalOpen(false);
      setParticipantToCancel(null);
    }
  };

  const handleShare = async () => {
    const eventDate = new Date(event.time).toLocaleString();
    const registrationDate = new Date(event.registrationStartTime).toLocaleString();
    const eventDay = getDayInLanguage(event.time, i18n.language);
    const registrationDay = getDayInLanguage(event.registrationStartTime, i18n.language);
    
    const shareMessage = t('checkOutEvent', {
      title: event.title,
      time: `${eventDate} (${eventDay})`,
      registrationTime: `${registrationDate} (${registrationDay})`,
      location: event.location,
      participants: `${event.maxParticipants}`
    });

    if (navigator.share) {
      try {
        await navigator.share({
          title: event.title,
          text: shareMessage,
          url: shareUrl
        });
      } catch (err) {
        console.log('Error sharing:', err);
      }
    } else {
      try {
        await navigator.clipboard.writeText(`${shareMessage}\n${shareUrl}`);
        setModalContent({
          title: t('success'),
          message: t('linkCopied')
        });
        setModalOpen(true);
      } catch (err) {
        console.log('Error copying to clipboard:', err);
      }
    }
  };

  if (!event) return <div>{t('loading')}</div>;
  console.log(event);
  console.log(localStorage.getItem('userId'));
  return (
    <div className="event-details">
      <div className="event-header">
        <h2>{event.title}</h2>
        <button 
          className="share-button-icon mobile-only" 
          onClick={handleShare}
          aria-label={t('shareEvent')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="currentColor" d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"/>
          </svg>
        </button>
      </div>
      <p>
        <strong>{t('time')}:</strong> {new Date(event.time).toLocaleString()} ({getDayInLanguage(event.time, i18n.language)})
      </p>
      {new Date() < new Date(event.registrationStartTime) && (
        <p><strong>{t('registrationStartTime')}:</strong> {new Date(event.registrationStartTime).toLocaleString()} ({getDayInLanguage(event.registrationStartTime, i18n.language)})</p>
      )}
      <p><strong>{t('location')}:</strong> {event.location}</p>
      <div className="event-info-container">
        {t('eventInfo')}:
        <div className="event-info-block">
          {event.eventInfo ? event.eventInfo : t('noEventInfo')}
        </div>
      </div>
      <p>{t('participants')}: {event.participants.length} / {event.maxParticipants}</p>
      <p>{t('waitlist')}: {event.waitlist.length}</p>
      <p>{countdown}</p>
      <h3>{t('registeredParticipants')}</h3>
      <ul>
        {event.participants.map((participant, index) => (
          <li key={index}>
            <span className="participant-index">{index + 1}.</span>
            {(userRole === 'manager' || userRole === 'admin' || 
              (isLoggedIn() && (
                participant.registeredBy === localStorage.getItem('userId') || 
                participant.userId === localStorage.getItem('userId')
              ))) && (
              <span 
                className="cancel-cross"
                onClick={() => handleCancelClick(participant)}
                title={t('cancelRegistration')}
              >
                &#10060;
              </span>
            )}
            <span className="participant-name">
              {participant.userId ? participant.username : `${participant.username} (${t('visitor')})`}
            </span>
          </li>
        ))}
      </ul>
      <h3>{t('waitlist')}</h3>
      <ul>
        {event.waitlist.map((participant, index) => (
          <li key={index}>
            <span className="participant-index">{index + 1}.</span>
            {(userRole === 'manager' || userRole === 'admin' || 
              (isLoggedIn() && (
                participant.registeredBy === localStorage.getItem('userId') || 
                participant.userId === localStorage.getItem('userId')
              ))) && (
              <span 
                className="cancel-cross"
                onClick={() => handleCancelClick(participant)}
                title={t('cancelWaitlist')}
              >
                &#10060;
              </span>
            )}
            <span className="participant-name">
              {participant.userId ? participant.username : `${participant.username} (${t('visitor')})`}
            </span>
          </li>
        ))}
      </ul>
      {canRegister && (
        isLoggedIn() ? (
          <div className="registration-form">
            <div className="name-inputs">
              <input
                type="text"
                value={visitorNames[0]}
                placeholder={t('enterYourName')}
                onChange={(e) => {
                  const newNames = [...visitorNames];
                  newNames[0] = e.target.value;
                  setVisitorNames(newNames);
                }}
                className="name-input"
              />
              <input
                type="text"
                placeholder={`${t('enterAdditionalName')} (${t('optional')})`}
                value={visitorNames[1]}
                onChange={(e) => {
                  const newNames = [...visitorNames];
                  newNames[1] = e.target.value;
                  setVisitorNames(newNames);
                }}
                className="name-input"
              />
              <input
                type="text"
                placeholder={`${t('enterAdditionalName')} (${t('optional')})`}
                value={visitorNames[2]}
                onChange={(e) => {
                  const newNames = [...visitorNames];
                  newNames[2] = e.target.value;
                  setVisitorNames(newNames);
                }}
                className="name-input"
              />
            </div>
            <button 
              className="btn btn-success" 
              onClick={handleRegister} 
              disabled={isLoading}
            >
              {isLoading ? t('processing') : t('registerForEvent')}
            </button>
          </div>
        ) : (
          <div className="registration-form">
            <div className="name-inputs">
              {visitorNames.map((name, index) => (
                <input
                  key={index}
                  type="text"
                  value={name}
                  placeholder={index === 0 ? 
                    t('enterYourName') : 
                    `${t('enterAdditionalName')} (${t('optional')})`}
                  onChange={(e) => {
                    const newNames = [...visitorNames];
                    newNames[index] = e.target.value;
                    setVisitorNames(newNames);
                  }}
                  className="name-input"
                  disabled={index === 0 && isLoggedIn()}
                />
              ))}
            </div>
            <button 
              className="btn btn-success" 
              onClick={handleRegister} 
              disabled={isLoading || !visitorNames.some(name => name.trim() !== '')}
            >
              {isLoading ? t('processing') : t('registerForEvent')}
            </button>
          </div>
        )
      )}
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={modalContent.title}
        message={modalContent.message}
        onConfirm={modalContent.onConfirm}
      />
      
      <Modal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        title={t('confirmCancellation')}
        message={t('confirmCancellationMessage', { name: participantToCancel?.username })}
        onConfirm={handleConfirmCancel}
        confirmText={t('confirm')}
        closeText={t('cancel')}
      />
    </div>
  );
}

export default EventDetails;
