import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { useTranslation } from 'react-i18next';

function Register() {
  const [formData, setFormData] = useState({ username: '', email: '', password: '', confirmPassword: '' });
  const [message, setMessage] = useState('');
  const history = useHistory();
  const { t } = useTranslation();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setMessage(t('passwordsDontMatch'));
      return;
    }
    try {
      const res = await axiosInstance.post('/api/users/register', formData);
      setMessage(t(res.data.msg));
      setTimeout(() => history.push('/login'), 5000);
    } catch (err) {
      setMessage(t(err.response?.data?.msg || 'serverError'));
    }
  };

  return (
    <div>
      <h2>{t('register')}</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit}>
        <input type="text" name="username" placeholder={t('username')} onChange={handleChange} required />
        <input type="email" name="email" placeholder={t('email')} onChange={handleChange} required />
        <input type="password" name="password" placeholder={t('password')} onChange={handleChange} required />
        <input type="password" name="confirmPassword" placeholder={t('confirmPassword')} onChange={handleChange} required />
        <button type="submit">{t('createAccount')}</button>
        <Link to="/login" className="login-link">{t('alreadyHaveAccount')}</Link>
      </form>
    </div>
  );
}

export default Register;