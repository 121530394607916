import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { useTranslation } from 'react-i18next';

function VerifyEmail() {
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const res = await axios.get(`${API_BASE_URL}/api/users/verify/${token}`);
        setMessage(res.data.msg);
      } catch (err) {
        setMessage(err.response ? err.response.data.msg : err.message);
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div>
      <h2>{t('emailVerification')}</h2>
      <p>{t(message)}</p>
    </div>
  );
}

export default VerifyEmail;
