import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const { t } = useTranslation();

  useEffect(() => {
    let timer;
    if (isButtonDisabled && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      setIsButtonDisabled(false);
      setCountdown(60);
    }
    return () => clearTimeout(timer);
  }, [isButtonDisabled, countdown]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    try {
      const res = await axiosInstance.post('/api/users/forgot-password', { email });
      setMessage(t(res.data.msg));
    } catch (err) {
      setMessage(t(err.response?.data?.msg || 'serverError'));
    }
  };

  const buttonStyle = {
    backgroundColor: isButtonDisabled ? '#cccccc' : '#007bff',
    color: isButtonDisabled ? '#666666' : '#ffffff',
    cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    transition: 'background-color 0.3s ease'
  };

  return (
    <div className="forgot-password-container">
      <h2>{t('forgotPassword')}</h2>
      <form onSubmit={handleSubmit}>
        {message && <div className="message">{message}</div>}
        <input
          type="email"
          name="email"
          placeholder={t('email')}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button 
          type="submit" 
          disabled={isButtonDisabled}
          style={buttonStyle}
        >
          {isButtonDisabled ? `${t('sendResetLink')} (${countdown}s)` : t('sendResetLink')}
        </button>
      </form>
    </div>
  );
}

export default ForgotPassword;